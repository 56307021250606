/* eslint-disable camelcase */
import Vue from 'vue'
import Axios from 'axios'
import API from '../../config/development'
import { map } from 'lodash'

const downloadURI = (uri, target, name) => {
    const link = document.createElement('a')
    if (name) link.download = name
    link.href = uri
    if (target) link.target = target
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const state = {
    tabledata: '',
    Settingsdata: {
        stgs_selected: '',
        stgs_name: '',
        stgs_username: '',
        stgs_endpoint: '',
        stgs_apikey: '',
        stgs_senderid: '',
        arguments: [],
        delete_id: ''
    },
    total_records: '',
    scheduledata: '',
    contactsdata: '',
    total_contact_list_records: '',
    total_schedule_records: '',
    blacklistData: '',
    blacklistCount: ''
}

const getters = {
    getTabledata: state => state.tabledata,
    getScheduledata: state => state.scheduledata,
    getcontactsdata: state => state.contactsdata,
    getBlacklistData: state => state.blacklistData
}

const mutations = {

    SET_TABLEDATA: (state, payload) => {
        state.tabledata = payload.result,
        state.total_records = payload.totalCount
    },

    ADD_SETTINGS: (state, payload) => {
        state.Settingsdata.stgs_selected = payload.sms_type
        state.Settingsdata.stgs_name = payload.sms_name
        state.Settingsdata.stgs_username = payload.sms_username
        state.Settingsdata.stgs_endpoint = payload.sms_endpoint
        state.Settingsdata.stgs_apikey = payload.sms_apikey
        state.Settingsdata.stgs_senderid = payload.sm_senderid
        state.Settingsdata.arguments = payload.sms_url_arguments
        state.Settingsdata.userid = payload.userid
    },
    DELETE_SETTINGS: (state, payload) => {
        state.Settingsdata.delete_id = payload.sms_sno
    },
    SET_SCHEDULE_TABLEDATA: (state, payload) => {
        state.scheduledata = payload.result
        state.total_schedule_records = payload.totalCount
    },
    SET_CONTACT_TABLEDATA: (state, payload) => {
        state.contactsdata = payload.result
        state.total_contact_list_records = payload.total
    },
    SET_BLACKLIST_DATA: (state, payload) => {
        state.blacklistData = payload.result
        state.blacklistCount = payload.total
    }
}

const actions = {

    async getSettingslist ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        console.log(data, 'pagination')
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/list-settings-data`, data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async getEndpointList ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-endpoint-names`, data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            return resp.data.result
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async getWhatsappEndpoints ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/endpoints`, payload)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            let result = []
            if (resp.data.result) {
                result = map(resp.data.result, (endpoint) => {
                    return endpoint.name
                })
            }
            return result
        } catch (error) {
            console.log('Error fetching whatsapp endpoints', error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async getTruecallerEndpoints ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/endpoints`, payload)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            let result = []
            if (resp.data.result) {
                result = map(resp.data.result, (endpoint) => {
                    return endpoint.name
                })
            }
            return result
        } catch (error) {
            console.log('Error fetching truecaller endpoints', error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async getRCSEndpoints ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/rcs/endpoints`, payload)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            let result = []
            if (resp.data.result) {
                result = map(resp.data.result, (endpoint) => {
                    return endpoint.name
                })
            }
            return result
        } catch (error) {
            console.log('Error fetching rcs endpoints', error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async settingSubmit ({ commit }, settings_data) {
        commit('ADD_SETTINGS', settings_data)
        commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/create-settings`, settings_data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.status == true) {
                commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
                return resp
            } else {
                commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            }
        } catch (error) {
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            console.log(error)
        }
    },
    async settingsDelete ({ commit, dispatch }, data) {
        commit('DELETE_SETTINGS', data)
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/delete-setting-record`, data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.status == true) {
                return resp
            // dispatch('getSettingslist');
            }
        } catch (error) {
            console.log(error)
        }
    },
    async contactDelete ({ commit, dispatch }, data) {
        commit('DELETE_SETTINGS', data)
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/contact-list-delete`, data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.status == true) {
            // dispatch('contactlistdata');
            }
        } catch (error) {
            console.log(error)
        }
    },
    async downloadFile ({ commit, dispatch }, data) {
        try {
            const resp = await this.$axios.get(`${API.Backend_URL}/download?filepath=`+data.serverFilename+`&filename=`+data.filename, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            const contentType = resp.headers['Content-Type'] || resp.headers['content-type']
            const blob = new Blob([resp.data], { type: contentType })
            const url = window.URL.createObjectURL(blob)
            downloadURI(url, '_blank', data.filename)
        } catch (error) {
            console.log(error)
        }
    },
    async getSettingsbyId ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/single-setting-data`, data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.length > 0) {
                commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
                return resp
            }
        } catch (error) {
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
            console.log(error)
        }
    },

    async settingUpdate ({ commit }, settings_data) {
        console.log(settings_data, 'settingUpdate')
        // commit('ADD_SETTINGS', settings_data)
        // commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        // try {
        //     const resp= await this.$axios.post(`${API.Backend_URL}/create-settings`, settings_data)

        // } catch (error) {
        //     commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        //     console.log(error);
        // }
    },

    async settingsSearch ({ commit }, search_data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/list-settings-data`, search_data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async schedularlistdata ({ commit }, search_data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-schedule-data`, search_data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_SCHEDULE_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async linklistdata ({ commit }, search_data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-schedule-data`, search_data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_SCHEDULE_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async contactlistdata ({ commit }, search_data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/get-contact-list`, search_data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_CONTACT_TABLEDATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async blacklistData ({ commit }, search_data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/upload_blacklist/get-blacklist`, search_data, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_BLACKLIST_DATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async getFilteredContacts ({ commit }, payload) {
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/upload_blacklist/get-filtered-contact-list`, payload, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                }
            })
            return { data: resp.data, success: true }
        } catch (error) {
            return { success: false }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
