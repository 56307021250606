import API from '../../config/development'
import { objToCsv, downloadURL } from '../../utils'
var { count } = require('sms-length')

const state = {
  tableData: []
}

const mutations = {
  SET_TABLEDATA: (state, payload) => {
    for(let item of payload){
      let smsdetail = count(item.message.msg)
      item.message.total_char = smsdetail.inCurrentMessage
      item.message.max_character = smsdetail.characterPerMessage
      item.message.sms_part = smsdetail.messages
    }
    state.tableData = payload
  },

  RESET_STATE: (state) => {
    state.tableData = []
  }
}

const actions = {
  async downloadVisitorList ({ state }, number) {
    try {
      const data = objToCsv(state.tableData)
      const fileName = 'user_events_' + number + '.csv'
      const blob = new Blob([data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      downloadURL(url, '_blank', fileName)
    } catch (error) {
      console.log(error)
    }
  },
  async getVisProfile ({ commit }, payload) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/visitor_profile`, payload, {
        headers: {
          'authorization': localStorage.getItem('Accesstoken')
        }
      })

      if (resp.status && resp.status === 200) {
        commit('SET_TABLEDATA', resp.data)
      } else {
        commit('RESET_STATE')
      }
    } catch (error) {
      console.log(error)
      commit('RESET_STATE')
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
