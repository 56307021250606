import User from './user'
import KeycloakAuth from './keycloak_auth'

const modules = {
    User,
    KeycloakAuth
}

export default {
    namespaced: true,
    modules
}
