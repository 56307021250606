import * as Msal from "msal";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  //check-l4l
  install (app, options) {
    let callback = (err, resp) => {}
    const authConfig = options.config.auth
    const msalInstance = new Msal.UserAgentApplication(options.config);
    msalInstance.authority = `https://${authConfig.instance}/tfp/${authConfig.tenant}/${authConfig.signInPolicy}/`
    // registering callback for redirection from Azure AD page
    if (options.redirectCallback && options.redirectCallback instanceof Function) {
      callback = options.redirectCallback
    }
    const login = () => {
      // Sending empty scopes for login currently as no scopes
      // are required for fetching id token currently
      // const loginRequest = {
      //   scopes: ["user.read", "mail.send"] // optional Array<string>
      // };
      msalInstance.authority = `https://${authConfig.instance}/tfp/${authConfig.tenant}/${authConfig.signInPolicy}/`
      msalInstance.loginRedirect({})
    }
    const register = () => {
      msalInstance.authority = `https://${authConfig.instance}/tfp/${authConfig.tenant}/${authConfig.signUpPolicy}/`
      msalInstance.loginRedirect({})
    }
    const resetPassword = () => {
      // Set the authority to the forgot password
      msalInstance.authority = `https://${authConfig.instance}/tfp/${authConfig.tenant}/${authConfig.resetPolicy}/`
      msalInstance.loginRedirect({})
    }

    msalInstance.login = login
    msalInstance.register = register
    msalInstance.resetPassword = resetPassword

    // inserting msal callback funstion here
    msalInstance.handleRedirectCallback(callback)
    app.config.globalProperties.$msal = msalInstance
  }
}