import Vue from 'vue'
import API from '../../constants/api'
import CONFIG from '../../config/development'
import JwtDecode from 'jwt-decode'

const getUserFromStorage = (token) => {
  const user = JwtDecode(token)
  if (!user.zone) { user.zone = 'Asia/Kolkata' }
  // Vue.nextTick(function () {
  //   Vue.$moment.tz.setDefault(user.zone)
  // })
  return user
}

const state = {
  isLoggedIn: false,
  isUserComplete: false,
  user: (() => {
    try {
      return getUserFromStorage(localStorage.Accesstoken)
    } catch (e) {
      return null
    }
  })()
}

const getters = {
  userInfo: state => state.user,
  isLoggedIn: state => state.isLoggedIn,
  isUserComplete: state => state.isUserComplete
}

const mutations = {
  SET_USER: (state, { token, loggedVia }) => {
    state.user = getUserFromStorage(token)
    state.user.loggedVia = loggedVia
  },
  USER_LOGGED_IN: (state) => {
    state.isLoggedIn = true
  },
  USER_AUTHENTICATED: (state, payload) => {
    state.isUserComplete = true
    state.user = getUserFromStorage(payload.token)
    // const [orgType, orgData] = checkOrgType(payload.resp.organization)
    state.user = {
      ...state.user,
      role: payload.resp.role,
      isActive: payload.resp.is_active,
      email: payload.resp.user.email,
      phone: {
        value: payload.resp.user.phone_number,
        isVerified: payload.resp.user.phone_number_verified
      },
      timeZone: payload.resp.user.time_zone,
      // organisation: { type: orgType, ...orgData },
      dc_mapping: payload.resp.dc_mapping,
      dashboard: payload.resp.organization.dashboard,
      toaster: payload.resp.organization.toast,
      username: payload.resp.username,
      partners_exists: payload.resp.partner_exists,
      subAccounts_exists: payload.resp.subaccount_exists,
      routingConfiguration_exists: payload.resp.routes_exists,
      connects_exists: payload.resp.connects_exists,
      truecaller_channel: payload.resp.truecaller_channel
    }
    state.user.loggedVia = payload.loggedVia
    /* Start : Set Default timezone by user api */
    // if (payload.resp.tz) {
    //   Vue.nextTick(function () {
    //     Vue.$moment.tz.setDefault(payload.resp.tz)
    //   })
    // }
    /* End : Set Default timezone by user api */
  }
}

const actions = {
  async setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  },
  signOut ({ commit }) {
    localStorage.removeItem('token')
    commit('setLogout')
  },
  async fetchUser ({ commit }, payload) {
    const resp = await this.$axios.post(`${CONFIG.Backend_URL}${API.auth.loginWisely}`)
    return resp
  },
  async userLoginSuccess ({ commit }, payload) {
    commit('USER_LOGGED_IN')
  },
  async userAuthenticationSuccess ({ commit }, payload) {
    localStorage.setItem('Accesstoken', payload.resp.token)
    commit('USER_AUTHENTICATED', { token: payload.resp.token, resp: payload.resp.data, loggedVia: 'msal' })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
