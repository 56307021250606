<template>
<div class="wrapper">
<nav id="sidebar" class="active">
<ul class="list-unstyled components mb-5">
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('campaigns') ? '' : 'noHover'" :to="'/Campdashboard/'+channelType">
<img class="mt-3 noHover" src="@/assets/images/campaign-1.png">
<p class="mt-2 noHover" style="font-size:10px">Campaigns</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :to="'/SettingsList/'+tab">
<img class="mt-3 noHover" src="@/assets/images/setting-1.png">
<p class="mt-2 noHover" style="font-size:10px">Settings</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('schedule') ? '' : 'noHover'" to="/Schedulelist">
<img class="mt-3 noHover" src="@/assets/images/schedule-1.png">
<p class="mt-2 noHover" style="font-size:10px">Schedule</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('contacts') ? '' : 'noHover'" to="/Contacts">
<img class="mt-3 noHover" src="@/assets/images/contact-1.png">
<p class="mt-2 noHover" style="font-size:10px">Contacts</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('baseline') ? '' : 'noHover'" to="/Baseline">
<img class="mt-3 noHover" src="@/assets/images/baseline_icon.svg">
<p class="mt-2 noHover" style="font-size:10px">Baseline</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('reports') ? '' : 'noHover'" to="/Reportslist">
<img class="mt-3 noHover" src="@/assets/images/report-1.png">
<p class="mt-2 noHover" style="font-size:10px">Reports</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" to="/ExportList">
<img class="mt-1 noHover" src="@/assets/images/download.svg">
<p class="mt-2 noHover" style="font-size:10px">Exports</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('links') ? '' : 'noHover'" to="/LinkDashboard">
<img class="mt-3 noHover" src="@/assets/images/link.png">
<p class="mt-2 noHover" style="font-size:10px">Links</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('user_profile') ? '' : 'noHover'" to="/Userprofile">
<img class="mt-3 noHover" src="@/assets/images/user-solid-white.svg">
<p class="mt-2 noHover" style="font-size:10px">User Profile</p>
</router-link>
</li>
<br><br>
<br><br><br><br><br><br>
<li>
<router-link to="">
<img class="mt-3 noHover" src="@/assets/images/help-1.png">
<p class="mt-2 noHover" style="font-size:10px">Help</p>
</router-link>
</li>
</ul>

</nav>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
let accessToken = localStorage.getItem('Accesstoken')
$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active')
        $(this).toggleClass('active')
    })
})
export default {
    name: 'Sidebar',
    data () {
        return {
            campaign: true
        }
    },
    props: {
        channelType: {
            type: String,
            default: 'sms'
        },
        tab: {
            type: String,
            default: 'channelSettings'
        }
    },
    computed: {
        ...mapGetters('Auth/KeycloakAuth', ['modSubModSet'])
    },
    methods: {
        created () {
            if (localStorage.getItem('reloaded')) {
                localStorage.removeItem('reloaded')
            } else {
                localStorage.setItem('reloaded', '1')
                location.reload()
            }
            if (!accessToken) {
                this.$router.push('/')
            }
        },
        logout () {
            localStorage.removeItem('Accesstoken')
            this.$router.push('/')
        }
    }
}
</script>

<style>
@import '../../css/navbar.css';
#sidebar{
    /* position: absolute; */
    padding-top: 50px;
    height: 100%;
}
#sidebar.active ul.components li a span{
    font-size:13px !important
}
#sidebar.active {
    min-width: 68px !important;
}
.active1{
    border-left-style: solid;
    border-left-color: red;
    border-left-width: 5px;
    /* border:'10 px solid #DF2027'; */
}
li.active {
  background-color: #41B883;
}
.exact-active{
    border-left-style: solid;
    border-left-color: #DF2027;
    border-left-width: 5px;
    background: #523588 !important;
}
/* li :hover{
    background: rgba(255, 255, 255, 0.5) !important;
} */

#sidebar ul li a {

     border-bottom: 0px !important;
}
#sidebar li :hover{
    background: #523588 !important;
    }
.noHover{
    pointer-events: none;
}
#sidebar.active ul.components li a {
    height: 63px;
    padding: 0px 0;
}
.wrapper{
    z-index: 30;
}
</style>
