const templateButtonsLimit = {
    'PHONE_NUMBER': 1,
    'URL': 2,
    'CALL_TO_ACTION': 4,
    'QUICK_REPLY': 10,
    'COPY_CODE': 1,
    'MARKETING_OPTOUT': 1,
    'COMPLETE_FORM': 1
}
export default {
    currencies: [
        { name: 'Afghan Afghani', code: 'AFA' },
        { name: 'Albanian Lek', code: 'ALL' },
        { name: 'Algerian Dinar', code: 'DZD' },
        { name: 'Angolan Kwanza', code: 'AOA' },
        { name: 'Argentine Peso', code: 'ARS' },
        { name: 'Armenian Dram', code: 'AMD' },
        { name: 'Aruban Florin', code: 'AWG' },
        { name: 'Australian Dollar', code: 'AUD' },
        { name: 'Azerbaijani Manat', code: 'AZN' },
        { name: 'Bahamian Dollar', code: 'BSD' },
        { name: 'Bahraini Dinar', code: 'BHD' },
        { name: 'Bangladeshi Taka', code: 'BDT' },
        { name: 'Barbadian Dollar', code: 'BBD' },
        { name: 'Belarusian Ruble', code: 'BYR' },
        { name: 'Belgian Franc', code: 'BEF' },
        { name: 'Belize Dollar', code: 'BZD' },
        { name: 'Bermudan Dollar', code: 'BMD' },
        { name: 'Bhutanese Ngultrum', code: 'BTN' },
        { name: 'Bitcoin', code: 'BTC' },
        { name: 'Bolivian Boliviano', code: 'BOB' },
        { name: 'Bosnia-Herzegovina Convertible Mark', code: 'BAM' },
        { name: 'Botswanan Pula', code: 'BWP' },
        { name: 'Brazilian Real', code: 'BRL' },
        { name: 'British Pound Sterling', code: 'GBP' },
        { name: 'Brunei Dollar', code: 'BND' },
        { name: 'Bulgarian Lev', code: 'BGN' },
        { name: 'Burundian Franc', code: 'BIF' },
        { name: 'Cambodian Riel', code: 'KHR' },
        { name: 'Canadian Dollar', code: 'CAD' },
        { name: 'Cape Verdean Escudo', code: 'CVE' },
        { name: 'Cayman Islands Dollar', code: 'KYD' },
        { name: 'CFA Franc BCEAO', code: 'XOF' },
        { name: 'CFA Franc BEAC', code: 'XAF' },
        { name: 'CFP Franc', code: 'XPF' },
        { name: 'Chilean Peso', code: 'CLP' },
        { name: 'Chinese Yuan', code: 'CNY' },
        { name: 'Colombian Peso', code: 'COP' },
        { name: 'Comorian Franc', code: 'KMF' },
        { name: 'Congolese Franc', code: 'CDF' },
        { name: 'Costa Rican ColÃ³n', code: 'CRC' },
        { name: 'Croatian Kuna', code: 'HRK' },
        { name: 'Cuban Convertible Peso', code: 'CUC' },
        { name: 'Czech Republic Koruna', code: 'CZK' },
        { name: 'Danish Krone', code: 'DKK' },
        { name: 'Djiboutian Franc', code: 'DJF' },
        { name: 'Dominican Peso', code: 'DOP' },
        { name: 'East Caribbean Dollar', code: 'XCD' },
        { name: 'Egyptian Pound', code: 'EGP' },
        { name: 'Eritrean Nakfa', code: 'ERN' },
        { name: 'Estonian Kroon', code: 'EEK' },
        { name: 'Ethiopian Birr', code: 'ETB' },
        { name: 'Euro', code: 'EUR' },
        { name: 'Falkland Islands Pound', code: 'FKP' },
        { name: 'Fijian Dollar', code: 'FJD' },
        { name: 'Gambian Dalasi', code: 'GMD' },
        { name: 'Georgian Lari', code: 'GEL' },
        { name: 'German Mark', code: 'DEM' },
        { name: 'Ghanaian Cedi', code: 'GHS' },
        { name: 'Gibraltar Pound', code: 'GIP' },
        { name: 'Greek Drachma', code: 'GRD' },
        { name: 'Guatemalan Quetzal', code: 'GTQ' },
        { name: 'Guinean Franc', code: 'GNF' },
        { name: 'Guyanaese Dollar', code: 'GYD' },
        { name: 'Haitian Gourde', code: 'HTG' },
        { name: 'Honduran Lempira', code: 'HNL' },
        { name: 'Hong Kong Dollar', code: 'HKD' },
        { name: 'Hungarian Forint', code: 'HUF' },
        { name: 'Icelandic KrÃ³na', code: 'ISK' },
        { name: 'Indian Rupee', code: 'INR' },
        { name: 'Indonesian Rupiah', code: 'IDR' },
        { name: 'Iranian Rial', code: 'IRR' },
        { name: 'Iraqi Dinar', code: 'IQD' },
        { name: 'Israeli New Sheqel', code: 'ILS' },
        { name: 'Italian Lira', code: 'ITL' },
        { name: 'Jamaican Dollar', code: 'JMD' },
        { name: 'Japanese Yen', code: 'JPY' },
        { name: 'Jordanian Dinar', code: 'JOD' },
        { name: 'Kazakhstani Tenge', code: 'KZT' },
        { name: 'Kenyan Shilling', code: 'KES' },
        { name: 'Kuwaiti Dinar', code: 'KWD' },
        { name: 'Kyrgystani Som', code: 'KGS' },
        { name: 'Laotian Kip', code: 'LAK' },
        { name: 'Latvian Lats', code: 'LVL' },
        { name: 'Lebanese Pound', code: 'LBP' },
        { name: 'Lesotho Loti', code: 'LSL' },
        { name: 'Liberian Dollar', code: 'LRD' },
        { name: 'Libyan Dinar', code: 'LYD' },
        { name: 'Lithuanian Litas', code: 'LTL' },
        { name: 'Macanese Pataca', code: 'MOP' },
        { name: 'Macedonian Denar', code: 'MKD' },
        { name: 'Malagasy Ariary', code: 'MGA' },
        { name: 'Malawian Kwacha', code: 'MWK' },
        { name: 'Malaysian Ringgit', code: 'MYR' },
        { name: 'Maldivian Rufiyaa', code: 'MVR' },
        { name: 'Mauritanian Ouguiya', code: 'MRO' },
        { name: 'Mauritian Rupee', code: 'MUR' },
        { name: 'Mexican Peso', code: 'MXN' },
        { name: 'Moldovan Leu', code: 'MDL' },
        { name: 'Mongolian Tugrik', code: 'MNT' },
        { name: 'Moroccan Dirham', code: 'MAD' },
        { name: 'Mozambican Metical', code: 'MZM' },
        { name: 'Myanmar Kyat', code: 'MMK' },
        { name: 'Namibian Dollar', code: 'NAD' },
        { name: 'Nepalese Rupee', code: 'NPR' },
        { name: 'Netherlands Antillean Guilder', code: 'ANG' },
        { name: 'New Taiwan Dollar', code: 'TWD' },
        { name: 'New Zealand Dollar', code: 'NZD' },
        { name: 'Nicaraguan CÃ³rdoba', code: 'NIO' },
        { name: 'Nigerian Naira', code: 'NGN' },
        { name: 'North Korean Won', code: 'KPW' },
        { name: 'Norwegian Krone', code: 'NOK' },
        { name: 'Omani Rial', code: 'OMR' },
        { name: 'Pakistani Rupee', code: 'PKR' },
        { name: 'Panamanian Balboa', code: 'PAB' },
        { name: 'Papua New Guinean Kina', code: 'PGK' },
        { name: 'Paraguayan Guarani', code: 'PYG' },
        { name: 'Peruvian Nuevo Sol', code: 'PEN' },
        { name: 'Philippine Peso', code: 'PHP' },
        { name: 'Polish Zloty', code: 'PLN' },
        { name: 'Qatari Rial', code: 'QAR' },
        { name: 'Romanian Leu', code: 'RON' },
        { name: 'Russian Ruble', code: 'RUB' },
        { name: 'Rwandan Franc', code: 'RWF' },
        { name: 'Salvadoran ColÃ³n', code: 'SVC' },
        { name: 'Samoan Tala', code: 'WST' },
        { name: 'Saudi Riyal', code: 'SAR' },
        { name: 'Serbian Dinar', code: 'RSD' },
        { name: 'Seychellois Rupee', code: 'SCR' },
        { name: 'Sierra Leonean Leone', code: 'SLL' },
        { name: 'Singapore Dollar', code: 'SGD' },
        { name: 'Slovak Koruna', code: 'SKK' },
        { name: 'Solomon Islands Dollar', code: 'SBD' },
        { name: 'Somali Shilling', code: 'SOS' },
        { name: 'South African Rand', code: 'ZAR' },
        { name: 'South Korean Won', code: 'KRW' },
        { name: 'Special Drawing Rights', code: 'XDR' },
        { name: 'Sri Lankan Rupee', code: 'LKR' },
        { name: 'St. Helena Pound', code: 'SHP' },
        { name: 'Sudanese Pound', code: 'SDG' },
        { name: 'Surinamese Dollar', code: 'SRD' },
        { name: 'Swazi Lilangeni', code: 'SZL' },
        { name: 'Swedish Krona', code: 'SEK' },
        { name: 'Swiss Franc', code: 'CHF' },
        { name: 'Syrian Pound', code: 'SYP' },
        { name: 'São Tomé and Príncipe Dobra', code: 'STD' },
        { name: 'Tajikistani Somoni', code: 'TJS' },
        { name: 'Tanzanian Shilling', code: 'TZS' },
        { name: 'Thai Baht', code: 'THB' },
        { name: "Tongan Pa'anga", code: 'TOP' },
        { name: 'Trinidad & Tobago Dollar', code: 'TTD' },
        { name: 'Tunisian Dinar', code: 'TND' },
        { name: 'Turkish Lira', code: 'TRY' },
        { name: 'Turkmenistani Manat', code: 'TMT' },
        { name: 'Ugandan Shilling', code: 'UGX' },
        { name: 'Ukrainian Hryvnia', code: 'UAH' },
        { name: 'United Arab Emirates Dirham', code: 'AED' },
        { name: 'Uruguayan Peso', code: 'UYU' },
        { name: 'US Dollar', code: 'USD' },
        { name: 'Uzbekistan Som', code: 'UZS' },
        { name: 'Vanuatu Vatu', code: 'VUV' },
        { name: 'Venezuelan BolÃvar', code: 'VEF' },
        { name: 'Vietnamese Dong', code: 'VND' },
        { name: 'Yemeni Rial', code: 'YER' },
        { name: 'Zambian Kwacha', code: 'ZMK' }
    ],
    channelToCostSettingsMap: { sms: 'SMS', tc: 'Truecaller', rcs: 'RCS', wpsms: 'Whatsapp' }, // 'et': 'Email', 'wpsms': 'Whatsapp',
    audienceTypeMapping: {
        1: 'Insights',
        2: 'Lookalike Audience',
        3: 'Custom Cohorts',
        4: 'Broadcasts'
    },
    goalMapping: {
    // 'cc': 'Credit Card',
    // 'is': 'Life Insurance',
    // 'ld': 'Lending',
        'gm:howz': 'Gaming Howz',
        'gm:my11': 'Gaming My11',
        'ld:mob': 'Lending Mob',
        'ld:navi': 'Lending Navi'
    },
    goals: [
    // {value:'cc',name:'Credit Card'},
    // {value:'is',name:'Life Insurance'},
    // {value:'lending',name:'Lending'},
        { value: 'ld:mob', name: 'Lending Mob' },
        { value: 'ld:navi', name: 'Lending Navi' },
        { value: 'gm:my11', name: 'Gaming My11' },
        { value: 'gm:howz', name: 'Gaming Howz' }
    ],
    whatsappRegex: /{{[0-9]+}}/g,
    whatsapp_vendor_list: [{ name: 'Wisely', value: 'wisely' }],
    truecaller_vendor_list: [{ name: 'Wisely', value: 'wisely' }],
    channelList: { sms: 'SMS', tc: 'Truecaller', rcs: 'RCS', wpsms: 'Whatsapp' },
    TypeFromRouteMap: {
        sms: 'sms',
        whatsapp: 'wpsms',
        truecaller: 'tc',
        rcs: 'rcs'
    },
    routeFromTypeMap: {
        sms: 'sms',
        wpsms: 'whatsapp',
        tc: 'truecaller',
        rcs: 'rcs'
    },
    channelToImageMap: {
        sms: require('@/assets/images/campicon.png'),
        wpsms: require('@/assets/images/Whatsapp-icon.svg'),
        tc: require('@/assets/images/truecaller.svg'),
        rcs: require('@/assets/images/truecaller.svg')
    },
    messagesTypeList: [
        { name: 'Transactional', value: 'TRANS' },
        { name: 'Promotional', value: 'PROMO' }
    ],
    truecallerMsgTypes: [
        { label: 'Text', value: 'text' },
        // { label: 'Rich Text', value: 'richtext' },
        { label: 'Image', value: 'image' },
        { label: 'Document', value: 'document' },
        { label: 'Video', value: 'video' },
        { label: 'Interactive', value: 'interactive' }
    ],
    truecallerHeaderTypes: [
        { label: 'Text', value: 'text' },
        // { label: 'Rich Text', value: 'richtext' },
        { label: 'Image', value: 'image' },
        { label: 'Document', value: 'document' },
        { label: 'Video', value: 'video' }
    ],
    tcButtonTypes: [
        { label: 'Reply', value: 'reply' },
        { label: 'Deeplink', value: 'deeplink' },
        { label: 'Telephone link', value: 'tellink' }
    ],
    channelsList: [
        { name: 'SMS', value: 'sms' },
        { name: 'Truecaller', value: 'tc' },
        { name: 'RCS', value: 'rcs' },
        { name: 'Whatsapp', value: 'wpsms' }
    ],
    rcsMsgTypes: [
        { label: 'Text', value: 'text' },
        { label: 'Rich Text', value: 'richtext' },
        { label: 'Image', value: 'image' },
        { label: 'Document', value: 'document' },
        { label: 'Video', value: 'video' },
        { label: 'Interactive', value: 'interactive' }],
    rcsHeaderTypes: [
        { label: 'Text', value: 'text' },
        { label: 'Rich Text', value: 'richtext' },
        { label: 'Image', value: 'image' },
        { label: 'Document', value: 'document' },
        { label: 'Video', value: 'video' }],
    rcsButtonTypes: [
        { label: 'Reply', value: 'reply' },
        { label: 'Deeplink', value: 'deeplink' },
        { label: 'Telephone link', value: 'tellink' }],
    rcsButtonTitles: {
        'dialer_action': ['Dialer Action'],
        'reply': ['Reply'],
        'view_location_query': ['View Location(Query)'],
        'view_location_latlong': ['View Location(Lat/Long)', 'Latitude', 'Longitude', 'Label'],
        'url_action': ['Url Action', 'Url to Open'],
        'share_location': ['Share Location'],
        'calendar_event': ['Calendar Event', 'Title', 'Description']
    },
    rcsMsgCategory: {
        'text_message': 'Text Message',
        'rich_text': 'Rich Card Stand-Alone',
        'carousel': 'Rich Card Carousel'
    },
    rcsShortCutsMap: {
        'Latitude': 'latitude',
        'Longitude': 'longitude',
        'Url to Open': 'url',
        'Label': 'label'
    },
    rcsTemplateOptions: {
        'actions': [
            { vcKey: 'reply', vcValue: 'Reply', vcAPIKey: 'reply', vcIcon: '', isImageIcon: false },
            { vcKey: 'url_action', vcValue: 'URL Action', vcAPIKey: 'url_action', vcIcon: 'pi-globe', isImageIcon: false },
            { vcKey: 'dialer_action', vcValue: 'Dialer Action', vcAPIKey: 'dialer_action', vcIcon: 'pi-phone', isImageIcon: false },
            { vcKey: 'vlocation', vcValue: 'View Location (Lat/Long)', vcAPIKey: 'view_location_latlong', vcIcon: 'pi-map-marker', isImageIcon: false },
            { vcKey: 'vlocation-q', vcValue: 'View Location (Query)', vcAPIKey: 'view_location_query', vcIcon: 'pi-map-marker', isImageIcon: false },
            { vcKey: 'rlocation', vcValue: 'Share Location', vcAPIKey: 'share_location', vcIcon: 'gps.svg', isImageIcon: true },
            { vcKey: 'event', vcValue: 'Calendar Event', vcAPIKey: 'calendar_event', vcIcon: 'pi-calendar', isImageIcon: false }
        ],
        'limit': {
            'text_message': 11,
            'rich_card': 4,
            'carousel': 4
        },
        'orientation': [
            { vcKey: 'VERTICAL', vcValue: 'Vertical' },
            { vcKey: 'HORIZONTAL', vcValue: 'Horizontal' }
        ],
        'alignment': [
            { vcKey: 'LEFT', vcValue: 'Left' },
            { vcKey: 'RIGHT', vcValue: 'Right' }
        ],
        'height': [
            { vcKey: 'SHORT_HEIGHT', vcValue: 'Short' },
            { vcKey: 'MEDIUM_HEIGHT', vcValue: 'Medium' }
        ],
        'width': [
            { vcKey: 'SHORT_WIDTH', vcValue: 'Small' },
            { vcKey: 'MEDIUM_WIDTH', vcValue: 'Medium' }
        ],
        'carousel_card': {
            min: 2,
            max: 10
        },
        'msg_desc_limit': {
            'text_message': 2500,
            'rich_card': 2000,
            'carousel': 2000
        }
    },
    UPLOADMODULEKEYS: {
        CHANNEL_WABA_PROFILE: 'CHANNEL_WABA_PROFILE',
        CHANNEL_WEBHOOK_SSL: 'CHANNEL_WEBHOOK_SSL',
        CHANNEL_TC_BUSINESS_LOGO: 'CHANNEL_TC_BUSINESS_LOGO',
        CHANNEL_TC_PROFILE: 'CHANNEL_TC_PROFILE',
        API_OUTGOING_SECURITY_SSL: 'API_OUTGOING_SECURITY_SSL',
        API_OUTGOING_WEBHOOK_SSL: 'API_OUTGOING_WEBHOOK_SSL',
        API_CONV_SECURITY_SSL: 'API_CONV_SECURITY_SSL',
        API_CONV_WEBHOOK_MO_SSC: 'API_CONV_WEBHOOK_MO_SSC',
        API_CONV_WEBHOOK_MT_SSC: 'API_CONV_WEBHOOK_MT_SSC',
        SETTINGS_BUSINESS_LOGO: 'SETTINGS_BUSINESS_LOGO',
        SETTINGS_BUSINESS_DOC: 'SETTINGS_BUSINESS_DOC',
        SETTINGS_PROFILE_PIC: 'SETTINGS_PROFILE_PIC',
        CHANNEL_RCS_BANNER_IMAGE: 'CHANNEL_RCS_BANNER_IMAGE',
        CHANNEL_RCS_AGENT_LOGO: 'CHANNEL_RCS_AGENT_LOGO',
        CHANNEL_RCS_BOT_LOGO: 'CHANNEL_RCS_BOT_LOGO',
        APPS_TEMPLATE_LOOKUP: 'APPS_TEMPLATE_LOOKUP',
        RCS_TEMPLATES_MEDIA: 'RCS_TEMPLATES_MEDIA',
        WAPP_TEMPLATES_MEDIA: 'WAPP_TEMPLATES_MEDIA'
    },
    templateButtonsMaxLimit: 10,
    templateButtons: [
        {
            vcKey: 'NONE',
            vcValue: 'None',
            id: 7
        },
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'URL',
            id: 2,
            vcValue: 'Call to Action - Visit Website',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['URL'],
            optionDisable: false,
            icon: 'pi pi-external-link',
            isCardButton: true
        },
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'PHONE_NUMBER',
            id: 1,
            vcValue: 'Call to Action - Phone Number',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['PHONE_NUMBER'],
            optionDisable: false,
            icon: 'pi pi-phone',
            isCardButton: true
        },
        {
            vcKey: 'QUICK_REPLY',
            subKey: 'QUICK_REPLY',
            vcValue: 'Quick Reply (Custom)',
            limit: templateButtonsLimit['QUICK_REPLY'],
            id: 6,
            optionDisable: false,
            icon: 'pi pi-replay',
            isCardButton: true
        },
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'COPY_CODE',
            id: 3,
            vcValue: 'Call to Action - Offer Code',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['COPY_CODE'],
            optionDisable: false,
            icon: 'pi pi-copy',
            isCardButton: false
        },
        /*
        {
        vcKey:'QUICK_REPLY',
        subKey:'MARKETING_OPTOUT',
        id:4,
        vcValue:'Marketing opt-out',
        limit: templateButtonsLimit['QUICK_REPLY'],
        subLimit : templateButtonsLimit['MARKETING_OPTOUT'],
        optionDisable:false,
        icon:"pi pi-times-circle",
        isCardButton:false,
        }, */
        {
            vcKey: 'CALL_TO_ACTION',
            subKey: 'FLOW',
            id: 5,
            vcValue: 'Complete Form',
            limit: templateButtonsLimit['CALL_TO_ACTION'],
            subLimit: templateButtonsLimit['COMPLETE_FORM'],
            optionDisable: false,
            icon: 'pi pi-list',
            isCardButton: false
        }
    ],
    channelEvMap: {
        'sms': {
            'click': 'sms_clicked'
        },
        'tc': {
            'click': '_tc_clicked'
        },
        'rcs': {
            'click': '_rcs_clicked'
        },
        'wpsms': {
            'click': '_whatsapp_clicked'
        }
    },
    LOGIN_LIMIT: 3
}
