<template>
    <InputValidator :name="field" :rules="rules" >
        <label v-if="label" class="tsl-form__label">{{ label }}</label>
        <input
            type="file"
            :class="{
                'tsl-form__control':false,
                'border--error': errors && errors.length,
                [classes.adjust || classes] : true
            }"
            :value="value"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="$emit('input', $event.target.value)"
            :accept="accept"
            @change="change"
            :multiple="multiple"
            />
        <div v-if="errors && errors.length" class="fileinput__error">{{ errors[0] }}</div>
    </InputValidator>
</template>

<script>
//import PrimeInput from "primevue/inputtext";

export default {
    components: { },
    props: {
        field: {
            type: String,
            default: ''
        },
        rules: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number, Array],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        accept:{
            type: String,
            default: 'image/*'
        },
        multiple:{
            type: Boolean,
            default: false
        },
        classes: {
            type: [String, Object],
            default:''
        },
    },
    methods:{
        change(event){
            this.$emit('change', event)
        }
    }
}
</script>

<style lang="scss" scoped>
.error {
    bottom: 0;
}
</style>
