import Vue from 'vue'
import API from '../../config/development'

const state = {
  tabledata: '',
  totalRecords: '',
  linkReportData: '',
  execTabledata: '',
  totalExecRecords: ''
}
const downloadURI = (uri, target, name) => {
  const link = document.createElement('a')
  if (name) link.download = name
  link.href = uri
  if (target) link.target = target
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
const getters = {
  getTabledata: (state) => state.tabledata,
  performance_data: (state) => state.linkReportData.performance,
  linkSummary_data: (state) => state.linkReportData.linkSummary,
  getExecTabledata: (state) => state.execTabledata
}

const mutations = {
  SET_TABLEDATA: (state, payload) => {
    state.tabledata = payload.result
    state.totalRecords = payload.totalCount
  },
  SET_REPORT_DATA: (state, payload) => {
    state.linkReportData = payload.data
  },
  SET_EXEC_TABLEDATA: (state, payload) => {
    state.execTabledata = payload.result
    state.totalExecRecords = payload.totalCount
  }
}

const actions = {
  async getLinksList ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(
        `${API.Backend_URL}/list-links-data`,
        data,
        {
          headers: {
            authorization: localStorage.getItem('Accesstoken')
          }
        }
      )
      commit('SET_TABLEDATA', resp.data)
    } catch (error) {
      console.log(error)
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  },
  async createEditLink ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
    try {
      const resp = await this.$axios.post(
        `${API.Backend_URL}/create-edit-link`,
        data,
        {
          headers: {
            authorization: localStorage.getItem('Accesstoken')
          }
        }
      )
      if (resp.data.status === true) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        return resp
      } else {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
      }
    } catch (error) {
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
      console.log(error)
    }
  },

  async getLinkReports ({ commit }, data) {
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/link-metrics`, data)
      commit('SET_REPORT_DATA', resp.data)
    } catch (error) {
      console.log(error)
    }
  },

  async getDownloadEventData ({ commit }, data) {
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/download-link-data`, data)
      const contentType = resp.headers['Content-Type'] || resp.headers['content-type']
      const contentDisposition = resp.headers['Content-Disposition'] || resp.headers['content-disposition']
      const fileName = contentDisposition.substring(contentDisposition.lastIndexOf('=') + 1).replaceAll(`"`, '')
      const blob = new Blob([resp.data], { type: contentType })
      const url = window.URL.createObjectURL(blob)
      downloadURI(url, '_blank', fileName)
    } catch (error) {
      console.log(error)
    }
  },

  async deleteLink ({ commit }, data) {
    try {
      const resp = await this.$axios.post(
        `${API.Backend_URL}/delete-link-record`,
        data,
        {
          headers: {
            authorization: localStorage.getItem('Accesstoken')
          }
        }
      )
      if (resp.data.status === true) {
        return resp
      }
    } catch (error) {
      console.log(error)
    }
  },
  async getLinkById ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', true, { root: true })
    try {
      const resp = await this.$axios.post(
        `${API.Backend_URL}/single-link-data`,
        data,
        {
          headers: {
            authorization: localStorage.getItem('Accesstoken')
          }
        }
      )
      if (resp.data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        return resp
      }
    } catch (error) {
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
      console.log(error)
    }
  },
  async getExecLinksList ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(
        `${API.Backend_URL}/link-exec-list`,
        data,
        {
          headers: {
            authorization: localStorage.getItem('Accesstoken')
          }
        }
      )
      commit('SET_EXEC_TABLEDATA', resp.data)
    } catch (error) {
      console.log(error)
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
