import SettingsManagement from "./settingsManagement";
import CostManagement from "./costManagement";
import WhatsappSettings from "./whatsappSettings";
import TruecallerSettings from "./truecallerSettings";
import RCSSettings from "./rcsSettings";

const modules = {
    SettingsManagement,
    CostManagement,
    WhatsappSettings,
    TruecallerSettings,
    RCSSettings,
};

export default {
    namespaced: true,
    modules,
};
