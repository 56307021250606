import { createApp, configureCompat } from 'vue';
configureCompat({
  MODE: 3,
})
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueRouter from 'vue-router'
import store from './store'
import PrimeVue from 'primevue/config'
import './css/main.scss'
/* import './assets/scss/main.scss'
import './assets/scss/vendor.scss' */
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import './css/overrides/index.scss'
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import ToastService from 'primevue/toastservice';
import RadioButton from "primevue/radiobutton";
import InputSwitch from 'primevue/inputswitch';
import InputFile from "./components/common/inputfile/InputFile.vue"
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import msal from './plugins/msal'
import axios from './plugins/axios'
import msalConfig from './config/msal'
import './plugins/validate'
import { Form, Field } from 'vee-validate';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import VueSelect from 'vue-select'
import ProgressBar from 'primevue/progressbar'
import Breadcrumb from 'primevue/breadcrumb'
import AppLayout from './components/layout/AppLayout.vue'
import DefaultLayout from './components/layout/DefaultLayout.vue'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import ProgressSpinner from 'primevue/progressspinner';
import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n'
import Sentry from './plugins/raven'
import InputNumber from 'primevue/inputnumber'
import { initializeKeycloak } from '../src/plugins/keycloak_auth'
import Chip from 'primevue/chip';
import Tag from 'primevue/tag';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Chart from 'primevue/chart';

import { setToast } from './utils';



const app = createApp(App);
app.use(PrimeVue);

app.component('app-layout', AppLayout)
app.component('no-layout', DefaultLayout)
app.component('Breadcrumb', Breadcrumb);
app.component('ProgressBar', ProgressBar);
app.config.globalProperties.moment = moment
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('DatePicker', DatePicker);
app.component('Card', Card);
// app.component('Button', Button);
app.component('Button', { 
  extends: Button,
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
    // or, for full vue 3 compat in this component:
  },
});
app.component('Form', Form);
app.component('Field', Field);
app.component("RadioButton", RadioButton);
app.component("ProgressSpinner", ProgressSpinner);
app.component('InputSwitch', InputSwitch);
app.component('InputFile', InputFile);
app.component('InputText', { 
  extends: InputText,
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
    // or, for full vue 3 compat in this component:
  },
});
app.component('InputNumber', InputNumber);
app.component('Dropdown', Dropdown);
// app.component('Dropdown', { 
//   extends: Dropdown,
//   compatConfig: {
//     MODE: 3,
//     COMPONENT_V_MODEL: false,
//     // or, for full vue 3 compat in this component:
//   },
// });
app.component('Toast', Toast);
app.component('Message', Message);
app.directive('tooltip', Tooltip);
app.component('VueSelect', VueSelect);
app.component('Textarea', Textarea);
app.component('Calendar', Calendar);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab)
app.component('Chip', Chip)
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Chart', Chart);
app.use(ToastService);
app.config.globalProperties.$axios = axios;
setToast(app.config.globalProperties.$toast)

const msalOptions = {
    config: {
        auth: {
            validateAuthority: false,
            tenant: msalConfig.tenant,
            clientId: msalConfig.clientId,
            instance: msalConfig.instance,
            redirectUri: `${window.location.origin}`,
            resetPolicy: msalConfig.resetPolicy,
            signInPolicy: msalConfig.signInPolicy,
            signUpPolicy: msalConfig.signUpPolicy,
            postLogoutRedirectUri: `${window.location.origin}`
        },
        cache: {
            cacheLocation: 'localStorage'
            // storeAuthStateInCookie: true
        }
    },
    redirectCallback: async (error, response) => {
    // handle redirect response or error
    // console.log(response)
    // localStorage.setItem('msal.login',true)
        localStorage.setItem('loggedVia', 'msal')
        // localStorage.setItem('msal.login.token',response.accessToken)
        if (response.accessToken)router.push('/Campdashboard/sms')
        // // An error should be there
        if (error && error.errorCode === 'access_denied') {
            // And the description should contain: AADB2C90118
            if (error.errorMessage.indexOf('AADB2C90118') > -1) {
                localStorage.setItem('adal.error.redirect', 'passwordChangeRequested')
            }
        }
    }
}



app.use(msal, msalOptions);
// app.use(VueRouter);

//Sentry.sentriImplem(app,router)

initializeKeycloak().then(() => {
    app.use(router);
    // app.use(axios);
    app.use(store);
    store.$axios = axios;
    app.mount('#app')
}).catch(err => {
    console.error('Keycloak initialization failed:', err)
})

export default app
