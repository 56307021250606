/* eslint-disable camelcase */
import Vue from "vue"
import Axios from 'axios'
import API from '../../config/development'
import cloneDeep from "lodash/cloneDeep";

const state = {

    currencyData: [],
    updatedData: [],
}

const getters = {

}

const mutations = {
    SET_CURRENCY_DATA: (state, payload) => {
        state.currencyData = payload
        state.updatedData = cloneDeep(payload)
    },
}

const actions = {
    async getCostSettingsList({ commit },data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/get-currency-data`,data,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_CURRENCY_DATA', resp.data.costDetails)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async updateCostSettings({ commit },data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
            
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/create-currency`,data,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            if (resp.data.status) {
                commit('SET_CURRENCY_DATA', data.currencyRecords)
                return 1
            } else {
                return 0
            }
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
