import Vue from 'vue'
import router from '../router'
import moment from 'moment'
import API from '../config/development'
import { keycloakSignout } from '../plugins/keycloak_auth'
const { count } = require('sms-length')

let toastInstance = null;

export const logout = async (context) => {
    await context.$axios.post(`${API.Backend_URL}/logout`)
    localStorage.removeItem('Accesstoken')

    if (localStorage.getItem('loggedVia') == 'msal') {
        localStorage.removeItem('loggedVia')
        localStorage.removeItem('msalaccessToken')
        await context.$msal.logout()
        router.push('/Loginpage')
    } else {
        localStorage.removeItem('loggedVia')
        keycloakSignout()
        // router.push('/Loginpage')
    }
}

export const objIsEmpty = (obj) => {
    for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false
        }
    }

    return JSON.stringify(obj) === JSON.stringify({})
}

// Returns true if arr1[0..n-1] and arr2[0..m-1]
// contain same elements.
export const arrayIsEqual = (arr1, arr2) => {
    // Length of the two array
    let n = arr1.length
    let m = arr2.length

    // If lengths of arrays are not equal
    if (n != m) { return false }

    // To store xor of both arrays
    let b1 = arr1[0]
    let b2 = arr2[0]

    // Find xor of each elements in array
    for (let i = 1; i < n; i++) {
        b1 ^= arr1[i]
    }
    for (let i = 1; i < m; i++) {
        b2 ^= arr2[i]
    }
    let allXor = b1 ^ b2

    // If xor is zero means they are
    // equal (5^5=0)
    if (allXor == 0) { return true }

    // If all elements were not same,
    // then xor will not be zero
    return false
}

export const deepClone = (obj, hash = new WeakMap()) => {
    if (Object(obj) !== obj) return obj // primitives
    if (hash.has(obj)) return hash.get(obj) // cyclic reference
    const result = obj instanceof Set ? new Set(obj) // See note about this!
        : obj instanceof Map ? new Map(Array.from(obj, ([key, val]) =>
            [key, deepClone(val, hash)]))
            : obj instanceof Date ? new Date(obj)
                : obj instanceof RegExp ? new RegExp(obj.source, obj.flags)
                // ... add here any specific treatment for other classes ...
                // and finally a catch-all:
                    : obj.constructor ? new obj.constructor()
                        : Object.create(null)
    hash.set(obj, result)
    return Object.assign(result, ...Object.keys(obj).map(
        key => ({ [key]: deepClone(obj[key], hash) })))
}

export const sleep = async (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}

export const copyToClipBoard = (text) => {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed' //  Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea)
        textarea.select()

        try {
            return document.execCommand('copy') // Security exception may be thrown by some browsers.
        } catch (ex) {
            getToast().add({
                severity: 'warn',
                summary: 'Copy Unsuccessful',
                detail: 'Your browser is preventing from copying the url!, Use latest version of Chrome/Firefox'
            })
            return false
        } finally {
            document.body.removeChild(textarea)
            getToast().add({
                severity: 'success',
                summary: 'Copy Successful',
                detail: 'The URL is copied to your clipboard'
            })
        }
    } else {
        getToast().add({
            severity: 'error',
            summary: 'Copy Failed',
            detail: 'Your Browser doesnot Support Copy, Try Latest version of Chrome/Firefox for best experience'
        })
    }
}

export const getNearestRoundedTime = (roundingCoeff) => {
    const coeff = 1000 * 60 * roundingCoeff // get nearest 5 mins time
    const date = new Date() // or use any other date
    return new Date(Math.round(date.getTime() / coeff) * coeff)
}

/*
  date: expects a date object (new Date())
*/
export const toIsoString = (date) => {
    const tzo = -date.getTimezoneOffset()
    const dif = tzo >= 0 ? '+' : '-'
    const pad = (num) => {
        return (num < 10 ? '0' : '') + num
    }

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60)
}

export const csvStringtoArray = async (text, delimiter) => {
    if (delimiter == '~') {
        let a = text.split(delimiter)
        a = a.filter(e => e && e.trim() != '')
        console.log(a)
        return a
    } else {
        var re_valid = `^\\s*(?:'[^'\\\\]*(?:\\\\[\\S\\s][^'\\\\]*)*'|"[^"\\\\]*(?:\\\\[\\S\\s][^"\\\\]*)*"|[^${delimiter}'"\\s\\\\]*(?:\\s+[^${delimiter}'"\\s\\\\]+)*)\\s*(?:${delimiter}\\s*(?:'[^'\\\\]*(?:\\\\[\\S\\s][^'\\\\]*)*'|"[^"\\\\]*(?:\\\\[\\S\\s][^"\\\\]*)*"|[^${delimiter}'"\\s\\\\]*(?:\\s+[^${delimiter}'"\\s\\\\]+)*)\\s*)*$`
        var re_value = `(?!\\s*$)\\s*(?:'([^'\\\\]*(?:\\\\[\\S\\s][^'\\\\]*)*)'|"([^"\\\\]*(?:\\\\[\\S\\s][^"\\\\]*)*)"|([^${delimiter}'"\\s\\\\]*(?:\\s+[^${delimiter}'"\\s\\\\]+)*))\\s*(?:${delimiter}|$)`
        re_valid = new RegExp(re_valid)
        re_value = new RegExp(re_value, 'g')
        // Return NULL if input string is not well formed CSV string.
        if (!re_valid.test(text)) return null
        var a = [] // Initialize array to receive values.
        text.replace(re_value, // "Walk" the string using replace with callback.
            function (m0, m1, m2, m3) {
                // Remove backslash from \' in single quoted values.
                if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
                // Remove backslash from \" in double quoted values.
                else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'))
                else if (m3 !== undefined) a.push(m3)
                return '' // Return empty string.
            })
        // Handle special case of empty last value.
        if (/,\s*$/.test(text)) a.push('')
        return a
    }
}

export const CountCharSms = (content_data) => {
    let smsLength = count(content_data.smsmessage)
    content_data.total_char = smsLength.inCurrentMessage
    content_data.max_character = smsLength.characterPerMessage
    content_data.sms_part = smsLength.messages
    return content_data
}
export const CountCharMultiSms = (content_data) => {
    let smsLength = count(content_data.smsmessage)
    content_data.total_char_multi = smsLength.inCurrentMessage
    content_data.max_character_multi = smsLength.characterPerMessage
    content_data.sms_part_multi = smsLength.messages
    return content_data
}

export const titleCase = (str) => {
    str = str.toLowerCase().split(' ')
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
}

export const isNumber = (evt) => {
    evt = evt || window.event
    let charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
    } else {
        return true
    }
}

export const downloadURL = (uri, target, name) => {
    const link = document.createElement('a')
    if (name) link.download = name
    link.href = uri
    if (target) link.target = target
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const objToCsv = (objArr) => {
    let keys = Object.keys(objArr[0])
    let rows = keys.join(',') + '\r\n'
    objArr.forEach((row) => {
        keys.forEach((key) => {
            let data = JSON.stringify(row[key])
            if ((data).includes(',')) {
                data = '"' + data.replaceAll('"', '""') + '"'
            }
            rows = rows + data + ','
        })
        rows = rows + '\r\n'
    })
    return rows
}

export const convertToReadable = (val) => {
    let value = parseFloat(val)
    if (val < 1000) {
        return value.toString()
    } else if (value >= 1000 && value < 1000000) {
        if (value % 1000 > 99) {
            return (value / 1000).toFixed(1) + 'K'
        } else {
            return Math.round(value / 1000) + 'K'
        }
    } else if (value >= 1000000 && value < 1000000000) {
        console.log('million')
        if (value % 1000000 > 99999) {
            return (value / 1000000).toFixed(1) + 'Mn'
        } else {
            return Math.round(value / 1000000) + 'Mn'
        }
    } else {
        console.log('billion')
        if (value % 1000000000 > 99999999) {
            return (value / 1000000000).toFixed(1) + 'Bn'
        } else {
            return Math.round(value / 1000000000) + 'Bn'
        }
    }
}

export const convertTimeStringToDate = (item) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    let date = new Date(item).toLocaleString('en-US', { timeZone: tz })
    return moment(date).format('DD MMM yy | hh:mm A')
}

export let mapModels = (nmStore, vars) => {
    let map = {}
    for (let v of vars) {
        map[v] = {
            get () {
                let mods = nmStore.split('/')
                let f = this.$store.state
                mods.forEach(m => { f = f[m] })
                return f[v]
            },
            set (val) {
                this.$store.commit('updateAnyState', [nmStore, v, val])
            }
        }
    }
    return map
}

export const modSubModConversion = async (rbacData) => {
    const resultSet = new Set()
    // Process each item in rbacData
    rbacData.forEach(item => {
        if (item.rsname !== 'Default Resource') {
            const [mainKey] = item.rsname.split('.')
            resultSet.add(item.rsname)
            resultSet.add(mainKey)

            // Check if rsname starts with 'campaigns.channels' and if 'schedule' scope is present
            if (item.rsname.startsWith('campaigns.channels')) {
                const hasScheduleScope = item.scopes.some(scope => scope.endsWith('schedule'))
                if (hasScheduleScope) {
                    resultSet.add('schedule')
                }
            }
        }
    })

    return resultSet
}

export const resourceScopeConversion = async (rbacData) => {
    const scopeMap = rbacData
        .filter(item => item.rsname !== 'Default Resource') // Filter out "Default Resource"
        .reduce((acc, item) => {
            acc[item.rsname] = new Set(item.scopes) // Map rsname to a Set of scopes
            return acc
        }, {})

    return scopeMap
}
export const setToast = (toast) => {
    toastInstance = toast
}

export const getToast = (toast) => {
    return toastInstance
}

