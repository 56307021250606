import Vue from 'vue'
import API from '../../constants/api'
import CONFIG from '../../config/development'
import { modSubModConversion, resourceScopeConversion } from '../../utils'
import axiosWebApi from '../../plugins/axios'


const state = {
    isLoggedIn: false,
    modSubModSet: new Set(),
    resourceScopeMap: {}
}

const getters = {
    isLoggedIn: state => state.isLoggedIn,
    modSubModSet: state => state.modSubModSet,
    resourceScopeMap: state => state.resourceScopeMap
}

const mutations = {
    SET_LOGGED_IN: (state, payload) => {
        state.isLoggedIn = payload
    },
    SET_ACCESS_TOKEN: (state, payload) => {
        localStorage.setItem('Accesstoken', payload)
    },
    SET_MOD_SUBMOD_DATA: (state, payload) => {
        state.modSubModSet = payload
        console.log('Test submod set::::::', state.modSubModSet)
    },
    SET_RESOURCE_SCOPE_MAP: (state, payload) => {
        state.resourceScopeMap = payload
        console.log('Resource Scope Map::::::', state.resourceScopeMap)
    }
}

const actions = {
    async setToken ({ commit, dispatch }, data) {
        if (!data.refresh) {
            commit('SET_LOGGED_IN', true)
        }
        commit('SET_ACCESS_TOKEN', data.token)
        dispatch('fetchRoles')
    },
    async fetchRoles ({ commit, dispacth }, data) {
        const resp = await axiosWebApi.post(`${CONFIG.Backend_URL}${API.auth.fetchScopes}`)
        if (resp.status === 200) {
            const modSubmodResp = await modSubModConversion(resp.data)
            const resourceScopeResp = await resourceScopeConversion(resp.data)
            commit('SET_MOD_SUBMOD_DATA', modSubmodResp)
            commit('SET_RESOURCE_SCOPE_MAP', resourceScopeResp)
            commit('SET_LOGGED_IN', false)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
