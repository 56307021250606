/* eslint-disable indent */
import Vue from 'vue'
import API from '../../config/development'
import { getToast } from '../../utils'

const state = {
    templateData: {},
    settingsData: [],
    templateList: [],
    sendersData: [],
    templateFormInfo: {},
    previewLoading: false,
    iORGID: '',
    accessKey: ''
}

const getters = {
}

const mutations = {
    SET_SETTINGS_DATA: (state, payload) => {
        state.settingsData = payload.result
        console.log(state.settingsData)
    },
    SET_TEMPLATE_LIST: (state, payload) => {
        state.templateList = payload
    },
    SET_TEMPLATE_DATA: (state, payload) => {
        state.templateData = payload
        state.templateFormInfo = payload
        console.log('state.templateFormInfo:::::::::::::::', state.templateFormInfo)
    },
    SET_SENDERS_DATA: (state, payload) => {
        state.sendersData = payload
    },
    SET_PREVIEW_LOADED: (state, payload) => {
        state.previewLoading = payload
    },
    SET_ORG_ID: (state, payload) => {
        state.iORGID = payload
    },
    SET_ACCESS_KEY: (state, payload) => {
        state.accessKey = payload
    }
}

const actions = {
    async getSettingsData ({ commit }) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/endpoints`, { })
            commit('SET_SETTINGS_DATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  getSenderList ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/fetch-wpsms-senders`, data)
            if (resp.data[0]) {
                commit('SET_SENDERS_DATA', resp.data)
            }
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  getTemplateList ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/fetch-wpsms-templates`, data)
            if (resp.data[0]) {
                commit('SET_TEMPLATE_LIST', resp.data)
            }
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  getTemplateData ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/fetch-wpsms-template-details`, data)
            commit('SET_TEMPLATE_DATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  saveContentData ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/create-campaign`, data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  sendTestWpsms ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/test-channel`, data)
            return resp;
        } catch (error) {
            console.log(error)
        }
    },
    async downloadMediaCall ({ commit }, mediaId) {
        try {
            const response = await this.$axios.get(`${API.Backend_URL}/downloadMediaWithId?media_id=` + mediaId + `&accessKey=` + state.accessKey, {
                headers: {
                    'authorization': localStorage.getItem('Accesstoken')
                },
                responseType: 'blob'
            })
            if (response.status == 200) {
                const blob = new Blob([response.data])
                const url = URL.createObjectURL(blob)
                return url
            } else {
                getToast().add({
                    severity: 'warn',
                    summary: 'Kindly add valid Media Id',
                    life: 3000
                })
                return ''
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
