<template>
  <!-- <SigningIn
    v-if="isLoggedIn && !passwordChangeState"
    :userFetched="userFetched"
    @endLoading="loadingHasEnded=true"
  /> -->

  <span>
    <!-- <Navbar />
    <Sidebar /> -->
    <router-view></router-view>
  </span>
</template>

<script>
import Navbar from './Navbar.vue'
import Sidebar from './Sidebar.vue'

export default {
    name: 'AppLayout',
    components: {
        Navbar,
        Sidebar
    }
}
</script>

<style lang="scss" scoped>
.background-clr {
  background-color: #f3f2f1 !important;
  height: 100vh;
}
</style>
