// import * as rules from 'vee-validate/dist/rules'
import { defineRule, configure } from 'vee-validate';
import { all, required as baseRequired, email, integer, alpha_num, numeric, min_value, confirmed, alpha_dash, max_value } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});
// install rules and localization
// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule])
// })
// localize('en', en);

// Customize required rules
defineRule('required',(value) => {
  if(!baseRequired(value)){
    return 'This field is required'
  }
  return true;
})

defineRule('min_value',(value, [val]) => {
  // ...min_value,
  const numericLength = Number(val)
  if(value >= val){
    return true
  }
  return `This field must be greater than ${val}`
})

defineRule('max_value', (value, [ max ]) => {
  const numericLength = Number(max);
  if(value <= numericLength){
    return true
  }
  return `This field must be greater than ${max}`
})

// Customize alpha_num rules
defineRule('integer',(value) => {
  if(integer(value)){
    return true
  }
  // TODO: add localized messages here - app.$t("models.tabs.partners")
  return 'This field must be an integer'
})
// Customize alpha_num rules
defineRule('alpha_num',(value) => {
  if(alpha_num(value)){
    return true
  }
  // TODO: add localized messages here - app.$t("models.tabs.partners")
  return 'This field may only contain alpha-numeric characters'
})

// Customise alpha_dash rules
defineRule('alpha_dash',(value) => {
  if(alpha_dash(value)){
    return true
  }
  // TODO: add localized messages here - app.$t("models.tabs.partners")
  return 'This field may only contain alphabetic characters, numbers, dashes or underscores.'
})
defineRule('min',(value, [ length ]) => {
  const numericLength = Number(length);
  if(value.length >= numericLength){
    return true
  }
  return `This field must be atleast ${length} characters`
})

// Customized validation for non-English words
defineRule('isEnglish', value => {
  let nonEnglishWords = ''
  for (let i = 0; i<value.length; i++) {
    if (value.charCodeAt(i)>128)nonEnglishWords = nonEnglishWords + value[i]
  }
  if (nonEnglishWords.length <= 0) return true
  return `Not supported characters : ${nonEnglishWords}`
})

// Numeric value
defineRule('numeric',(value) => {
  if(numeric(value)){
    return true
  }
  return 'This field must contain only numerics'
})

defineRule('url', value => {
  if( /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/ig.test(value.trim()) ){
    return true
  }
  return 'This field must contain url only'
})

//checking http
defineRule('excludeUrlScheme', value => {
  if(value.indexOf('http') !=0){
    return true
  }
  return 'This field must not start with http or https'
})

defineRule('atLeastOne', (value) => {
    if(!value){
      return 'One of the field is required'
    }
    return true
})