/* eslint-disable indent */
import Vue from 'vue'
import API from '../../config/development'

const state = {
    settingsData: [],
    senderIds: [],
    iSVCAccountID: '',
    iORGID: '',
    templateData: [],
    templateDetail: {},
    previewLoading: false
}

const getters = {
}

const mutations = {
    SET_SETTINGS_DATA: (state, payload) => {
        state.settingsData = payload.result
    },
    SET_SENDER_IDS: (state, payload) => {
        state.senderIds = payload.result
    },
    SET_ORG_DETAILS: (state, payload) => {
        state.iSVCAccountID = payload.iSVCAccountID
        state.iORGID = payload.iORGID
    },
    SET_TEMPLATE_DATA: (state, payload) => {
        state.templateData = payload.result
    },
    SET_PREVIEW_LOADED: (state, payload) => {
        state.previewLoading = payload
    },
    SET_TEMPLATE_INFO: (state, payload) => {
        let tmptDetails = JSON.parse(payload.result).jTemplateObject
        if (tmptDetails.category === 'text_message') {
            state.templateDetail.msg_type = 'Text Message'
        } else if (tmptDetails.category === 'rich_text') {
            state.templateDetail.msg_type = 'Rich Card Stand-Alone'
        } else {
            state.templateDetail.msg_type = 'Rich Card Carousel'
        }
        state.templateDetail.components = tmptDetails.components
    }
}

const actions = {
    async getSettingsData ({ commit }) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/endpoints`, { })
            commit('SET_SETTINGS_DATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  saveContentData ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            await this.$axios.post(`${API.Backend_URL}/create-campaign`, data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async  sendTestTruecaller ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/test-channel`, data)
            return resp
        } catch (error) {
            console.log(error)
        }
    },
    async getSenderIds ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            commit('SET_ORG_DETAILS', payload)
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/senderIds`, payload)
            commit('SET_SENDER_IDS', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async getTemplateData ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/templates`, payload)
            commit('SET_TEMPLATE_DATA', resp.data)
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
    async fetchTemplateInfo ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/templateInfo`, payload)
            commit('SET_TEMPLATE_INFO', resp.data)
            return { tempDetail: JSON.parse(resp.data.result).jTemplateObject, agentInfo: { vcAgentName: JSON.parse(resp.data.result).vcAgentName, vcLogo: JSON.parse(resp.data.result).vcLogo } }
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
