import Vue from "vue"
import API from '../../config/development'
import CONSTANTS from '../../constants/index'

const state = {
    tableData: '',
    totalRecords: 0,
    productList : CONSTANTS.goals,
}

const getters = {
    getTabledata: state => state.tableData,
    totalRecords: state => state.totalRecords,
    getProducts : state => state.productList
}

const mutations = {
    SET_TABLEDATA : (state, payload) => {
        state.totalRecords = payload.totalRecords
        state.tableData = payload.tableData
    }
}

const actions = {
    async getBaselineList ({ commit }, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        try {
            const { data } = await this.$axios.post(`${API.Backend_URL}/baseline/get-baseline-list`, payload,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            commit('SET_TABLEDATA', {
                totalRecords : data.totalCount,
                tableData : data.result
            })
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async saveBaseline ({commit}, payload) {
        let campaignData;
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try{
            if(payload.selectedMethod == 'upload_and_run'){
                payload.isBaselineCampaign = 1;
                console.log("Selected upload and run  - ")
                const resp = await this.$axios.post(`${API.Backend_URL}/create-campaign`, payload, {
                    headers : {
                        "authorization": localStorage.getItem('Accesstoken')
                    }
                })
                campaignData = resp.data
                payload.campaignId = campaignData.campid;
            }
            const { data } = await this.$axios.post(`${API.Backend_URL}/baseline/createedit`, payload,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return campaignData;
        }catch(error) {
            console.log(error)
            // commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async getSingleBaselineData ({commit}, payload) {
        commit('LOADING_SPINNER_SHOW_MUTATION', true,{root:true})
        try {
            const { data } = await this.$axios.post(`${API.Backend_URL}/baseline/get-baseline-info`, {
                id : payload.id
            },{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            let baselineData = data.result;
            let campaignData = {}
            if(baselineData.campaignId){
                const campData = await this.$axios.post(`${API.Backend_URL}/get-camp-data`, {
                    "campId": baselineData.campaignId
                    },{
                    headers : {
                        "authorization":localStorage.getItem('Accesstoken')
                    }
                });
                campaignData = campData.data.data;
            }
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
            return {
                baselineData,
                campaignData
            };
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false,{root:true})
        }
    },
    async setCurrent ({commit}, payload) {
        try {
            const { data } = await this.$axios.post(`${API.Backend_URL}/baseline/set-baseline`, payload,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            return data;
        } catch (error) {
            console.log(error)
        }
    },
    async cancelBaseline ({commit}, payload) {
        try {
            const { data } = await this.$axios.post(`${API.Backend_URL}/baseline/cancel`, payload,{
                headers : {
                    "authorization":localStorage.getItem('Accesstoken')
                }
            })
            return data;
        } catch (error) {
            console.log(error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
