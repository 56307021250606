import app from '../main'
import store from '../store'
import JwtDecode from 'jwt-decode'
import msalConfig from '../config/msal'
import router from '../router'
import Axios from 'axios'
import API from '../config/development'
import Vue from 'vue'
import { sleep, logout } from '../utils'

const landingPage = 'Campdashboard'

export const authenticateUser = async (token, noRouting = false) => { // User Authorization
  // Once user logged in retry 5 times if token is authentiacted by azure yet
  const AUTHENTICATE_RETRY = 5
  const isLoggedIn = store.getters.isLoggedIn
  // console.log("Assigning token - ", token)
  await localStorage.setItem('msalaccessToken', token)
  let retries = 0
  while (retries < AUTHENTICATE_RETRY) {
    try {
      const authenticateResp = await store.dispatch('Auth/fetchUser')
      if (authenticateResp.status === 200) {
        // If Admin is logged in into User Interface
        if (authenticateResp.data.data.role === 'admin') { // Redirecting to Signout Page admin
          await localStorage.setItem('adminUserLoggedin', 'adminUserLoggedin')
          app.$router.push({ name: 'signout' })
          return
        }
        await store.dispatch('Auth/userAuthenticationSuccess', { token, resp: authenticateResp.data })
        await sleep(500)
        retries = AUTHENTICATE_RETRY
        if (noRouting) {
          return
        }
        // All custom routing logics should come here
        if (!authenticateResp.data.data.user.phone_number_verified) { // Redirect Mobile OTP Verify
          app.$router.push({ name: verifyPage })
          return
        }
        var staticPages = ['404', 'terms-conditions', 'aggrement', 'need-help', 'gateway-node', 'edgenodehelpdoc', 'edgenodehelpdocType', 'apidoc', 'user-email-invite']
        if (!authenticateResp.data.data.organization.dashboard && staticPages.includes(app.$route.name) == false) { // Redirect Dashboard
          app.$router.push({ name: landingPage })
          return
        }
        // Default redirecting landing page when login or signup
        if (app.$route.name !== landingPage && staticPages.includes(app.$route.name) == false && (app.$route.meta.noSidebar || !isLoggedIn)) {
          /* un-comment when email login enabled
          if(localStorage.getItem("email.redirect")){
            var emailRedirectPage = localStorage.getItem("email.redirect");
            localStorage.removeItem("email.redirect");
            app.$router.push({ name: emailRedirectPage });
          }else{
            app.$router.push({ name: landingPage })
          } */
          app.$router.push({ name: app.$route.name, params: app.$route.params, query:app.$route.query}).catch(()=>{});
        }
      } else if (authenticateResp.status === 401) {
        if (authenticateResp.data.messages && ((authenticateResp.data.messages).toLowerCase() != 'immature signature')) { // as discussed with guru
          // Concurrent Session handing
          if (authenticateResp.data.messages == 'Already logged in') {
            localStorage.setItem('userConcurrentCheck', 1)
          }
          retries = AUTHENTICATE_RETRY
          app.$router.push('/signout')
          // app.$toast.add({severity:'error',summary: "error test message",detailed: authenticateResp.data.messages,life:2000});
          // app.$toast.add({summary: "Invalid Request, Please try again later." ,life:2000});
          // await sleep(2000);
        }
        if (retries === (AUTHENTICATE_RETRY - 1)) {
          // TODO: Show corresponding error message and redirect user
        }
      }
    } catch (err) {
      console.log('user could not be authenticated', err)
    }
    retries++
    await sleep(retries * retries * 300)
  }
}

export const getTokenAndAuthenticate = async (msalInstance, noRouting = false) => { // MSAL library handling
  const tokenRequest = {
    scopes: [`https://${msalConfig.tenant}/api/demo.read`]
  }
  try {
    // if the user is already logged in you can acquire a token
    if (msalInstance.getAccount()) {
      // Check if user came from change password page
      const idToken = JwtDecode(msalInstance.cacheStorage.getItem('idtoken'))
      if (idToken.acr === 'b2c_1a_passwordreset') {
        await store.dispatch('passwordChangeSuccess')
        await sleep(750)
        localStorage.setItem('adal.error.redirect', 'passwordChangeSuccess')
        logout()
        return
      }
      await store.dispatch('Auth/userLoginSuccess')
      // get access token from response
      const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest)
      localStorage.setItem("tokenExpiry", tokenResponse.expiresOn.getTime());
      await authenticateUser(tokenResponse.accessToken, noRouting)
    } else {
      const msalError = localStorage.getItem('adal.error.redirect')
      if (msalError === 'passwordChangeRequested') {
        await store.dispatch('passwordChangeRequested')
        if (!window.location.hash) {
          await sleep(1500)
          localStorage.removeItem('adal.error.redirect')
          msalInstance.resetPassword()
        }
      } else if (msalError === 'passwordChangeSuccess') {
        await store.dispatch('passwordChangeSuccess')
        await sleep(750)
        localStorage.removeItem('adal.error.redirect')
        msalInstance.login()
      } else {
        // user is not logged in, you will need to log them in to acquire a token
        app.$router.push('/')
        // router.push('/Loginpage')
        // msalInstance.login()
      }
    }
  } catch (err) {
    // TODO; Need to catch ClientAuthError and do analysis for it
    console.log('got error', err) // -- remove after test
    // could also check if err instance of InteractionRequiredAuthError if you can import the class.
    if (err.name === 'InteractionRequiredAuthError') {
      // console.log('interaction is required by user for logging in') -- remove after test
      try {
        // get access token from response
        const tokenResponse = await msalInstance.acquireTokenRedirect(tokenRequest)
        await authenticateUser(tokenResponse.accessToken, noRouting)
      } catch (err) {
        // handle error --  todo
        console.log('even interaction call failed', err) // -- remove after test
      }
    }
  }
}
