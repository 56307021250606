import smsContent from '../campaign/smsContent'

const modules = {
  smsContent
}

export default {
  namespaced: true,
  modules
}
