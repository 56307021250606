/* eslint-disable indent */
import Vue from 'vue'
import API from '../../config/development'
import { getToast } from '../../utils'

const state = {
    truecallerTableData: [],
    truecaller_total_records: 0
}

const getters = {
    getTruecallerTabledata: state => state.truecallerTableData
}

const mutations = {
    SET_TABLEDATA: (state, payload) => {
        state.truecallerTableData = payload.result
        state.truecaller_total_records = payload.totalCount
    }
}

const actions = {

    async getTruecallerSettingslist ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/truecaller-settings-list`, data)
            if (!resp.data.result && resp.status !== 401) {
                getToast().add({
                    severity: 'error',
                    summary: 'Error while fetching truecaller settings',
                    life: 3000
                })
            } else {
                commit('SET_TABLEDATA', resp.data)
            }
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async getTruecallerSettingById ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/truecaller-detail`, data)
            return resp.data
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async deleteTruecallerSetting ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/truecaller-delete`, data)
            return resp.data
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    },

    async createTruecallerSetting ({ commit }, data) {
        commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/settings/truecaller/createedit`, data)
            return resp.data
        } catch (error) {
            console.log(error)
            commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
