
export default {
    Gamoga_API: process.env.VUE_APP_GAMOOGA_URL,
    Backend_URL: process.env.VUE_APP_BACKEND_URL,
    Preview_URL: process.env.VUE_APP_PREVIEW_URL,
    Cm_ui_URL: process.env.VUE_APP_CM_UI_URL,
    Keycloak_URL: process.env.VUE_APP_KEYCLOAK_URL,
    Keycloak_Realm: process.env.VUE_APP_KEYCLOAK_REALM,
    Keycloak_ClienId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    sentryDSN: process.env.VUE_APP_SENTRY_DSN,
    SampleBaselineFilePath: 'uploads/sample/baseline_sample.csv',
    SampleBlacklistFilePath: 'uploads/sample/blacklist_sample.csv',
    SampleBaselineFileName: 'baseline_sample.csv',
    SampleBlacklistFileName: 'blacklist_sample.csv'
}
