import LinksManagement from './linksManagement'

const modules = {
  LinksManagement
}

export default {
  namespaced: true,
  modules
}
