import API from '../config/development'
import Keycloak from 'keycloak-js'
import store from '../store'

let initOptions = {
    'url': API.Keycloak_URL,
    'realm': API.Keycloak_Realm,
    'clientId': API.Keycloak_ClienId
}
export const keycloak = new Keycloak(initOptions)

export const keycloakSignout = () => {
    keycloak.logout()
}

export const initializeKeycloak = () => {
    return new Promise((resolve, reject) => {
        keycloak.init({
            onLoad: 'login-required', // Ensure this is set in the init method
            checkLoginIframe: false // Disable login iframe check
        }).then(async (authenticated) => {
            if (authenticated) {
                refreshTokenFunc()
                console.log('Keycloak token', keycloak.token)
                console.log('Keycloak ID token', keycloak.idToken)
                await store.dispatch('Auth/KeycloakAuth/setToken', {
                    'token': keycloak.token })
                resolve(keycloak)
            } else {
                console.error('User is not authenticated')
                window.location.reload() // or handle as needed
            }
        }).catch((error) => {
            console.log('Keycloak initialization error:', error)
            reject(error)
        })
    })
}

export const refreshTokenFunc = () => {
    setInterval(() => {
        // updateToken parameter is the minValidaty time
        keycloak.updateToken(70).then(async (refreshed) => {
            if (refreshed) {
                console.log('Token refreshed ' + refreshed)
                await store.dispatch('Auth/KeycloakAuth/setToken', {
                    'token': keycloak.token, 'refresh': true })
            } else {
                console.log('Token not refreshed, valid for ' +
                Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
            }
        }).catch((error) => {
            console.log('Failed to refresh token' + error)
        })
    }, 60000)
}
