import CampaignManagement from './campaignManagment'
import ReportsDashboard from './reportsDashboard'
import VisitorProfile from './visitorProfile'
import WhatsappManagement from './whatsappManagement'
import TruecallerManagement from './truecallerManagement'
import smsContent from './smsContent'
import RCSManagement from './rcsManagement'

const modules = {
  CampaignManagement,
  WhatsappManagement,
  TruecallerManagement,
  ReportsDashboard,
  VisitorProfile,
  smsContent,
  RCSManagement
}

export default {
  namespaced: true,
  modules
}
