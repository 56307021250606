import BaselineManager from './baselineManagement'

const modules = {
	BaselineManager
}

export default {
  namespaced: true,
  modules
}
