<template>
  <div>
    <TheLoader v-if="showLoading"></TheLoader>
    <TheLoader v-if="showAuthLoader"></TheLoader>
    <TheLoader v-if="isLoggedIn"></TheLoader>
    <component v-else :is="this.$route.meta && this.$route.meta.noSidebar ? 'app-layout': 'no-layout'">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TheLoader from '../src/components/common/loader/Loader.vue'
import { getTokenAndAuthenticate } from './plugins/auth'
import moment from 'moment'

const RECHECK_LOOP = 2
const RECHECK_OFFSET = 5

export default {
    name: 'App',
    created () {
        setInterval(() => {
            this.ensureLogin()
        }, 1000 * 60 * RECHECK_LOOP)
    },
    computed: {
        ...mapState({
            showLoading: (state) => state.showLoading
        }),
        ...mapGetters({ isUserComplete: 'Auth/isUserComplete', isLoggedIn: 'Auth/KeycloakAuth/isLoggedIn' }),
        showAuthLoader () {
            if (localStorage.loggedVia == 'msal') {
                return (this.isLoggedIn && !this.isUserComplete)
            } else {
                return false
            }
        }
    },
    components: {
        TheLoader
    },
    methods: {
        async ensureLogin () {
            if (localStorage.getItem('msalaccessToken') && localStorage.getItem('loggedVia') == 'msal') {
                const tokenExpiry = localStorage.getItem("tokenExpiry")?(localStorage.getItem("tokenExpiry")/1000):this.$msal.getAccount().idToken.exp;
                const isExpiryNear = moment().add(RECHECK_OFFSET, 'm').isAfter(moment.unix(tokenExpiry))
                if (isExpiryNear) {
                    getTokenAndAuthenticate(this.$msal, true)
                }
            }
        }
    },
    mounted () {
        if (localStorage.getItem('loggedVia') == 'msal' && window.location.pathname != '/Signout') {
            getTokenAndAuthenticate(this.$msal)
        }
    }
}
</script>
