import { createStore } from 'vuex'


//import files here each time you make a new module store file
import Auth from './auth'
import SettingsManagement from './settings'
import LinksManagement from './links'
import CampaignManagement from './campaign'
import ReportsDashboard from './campaign'
import BaselineManagement from './baseline'
import ContentManagement from './content'

const store = createStore({
    //strict: process.env.NODE_ENV !== 'production',
    modules: {
        Auth,
        SettingsManagement,
        CampaignManagement,
        ReportsDashboard,
        LinksManagement,
        BaselineManagement,
        ContentManagement
    },
    state() {
        return {
            showLoading: false,
        };
    },
    mutations: {
        LOADING_SPINNER_SHOW_MUTATION:(state, payload)=> {
            state.showLoading = payload;
        },
        updateAnyState (state, payload) {
            let obj = state
            let nmState = payload.shift()
            let nms = nmState.split('/')
            payload.unshift(...nms)
            let val = payload.pop()
            let key = payload.pop()
            payload.forEach(p => { obj = obj[p] })
            obj[key] = val
        }
    },
    actions: {
     
    }
})

export default store
